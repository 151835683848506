import styled from "styled-components";

export default styled.div`
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #222;
    transform: translateX(100%);
    transition: transform 100ms linear;
    z-index: 800;
    
    &.expanded {
        transform: translateX(0%);
    }

    @media screen and (min-width: 500px) {
        display: table;
        vertical-align: bottom;
        top: 0;
        right: 50px;
        height: 65px;
        left: initial;
        bottom: initial;
        background: none;
        transform: translateX(0%);
    }
`;