import Container from "./Container";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Explore() {


    return (
        <Container>
            <h3>Explore</h3>
            <KeyboardArrowDownIcon />
        </Container>
    );
}