import Navbar from "../../components/Navbar";
import MainContent from "../../components/MainContent";
import Article from "../../components/Article";

export default function DCSM() {
    return (
        <>
            <Navbar />

            <MainContent>
                <Article>
                    <h1>DMX Controller Serial Management Protocol</h1>

                    <h2>
                        About
                    </h2>

                    <p>
                        DCSM is a protocol designed for controlling USB DMX controllers.
                        Over other protocols, DCSM has a couple of key benefits. DCSM
                        includes two separate interfaces: a more human-friendly command
                        interface and a machine-focused direct control interface. This
                        offers the flexibility of controlling DCSM devices from any device
                        with a serial monitor (which is available on almost every device
                        with a USB port, including most phones) even if there is no native
                        software implementation for the direct control DCSM protocol. DCSM
                        is designed for buffered DMX devices, which means constant signalling
                        is not required. DCSM also has many different instructions available
                        within the direct control interface. One of the benefits of this is
                        the ability to only change specific addresses instead of needed to
                        transmit entire universes of data, which frees up bandwidth for other
                        messages.
                    </p>

                    <h2>Additional Information</h2>

                    <p>
                        Additional information can be found here:

                        <ul>
                            <li>
                                <a href="https://cdn.goddard.systems/dcsm/specification/latest" target="_blank"
                                   rel="noreferrer noopener">
                                    Latest DCSM Specification/Documentation
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/Buckell/dcsm" target="_blank" rel="noreferrer noopener">
                                    DCSM Controller Library Repository
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/Buckell/dcsmjs" target="_blank" rel="noreferrer noopener">
                                    DCSM.js (Node.js Control API) Repository
                                </a>
                            </li>
                        </ul>
                    </p>
                </Article>
            </MainContent>
        </>
    );
}