import Navbar from "../../../components/Navbar";
import MainContent from "../../../components/MainContent";
import Article from "../../../components/Article";
import {Link} from "react-router-dom";
import Code from "../../../components/Code";

export default function ProtonDocs() {
    return (
        <>
            <Navbar />

            <MainContent>
                <Article>
                    <h1>Proton Docs</h1>

                    <h2>
                        References
                    </h2>

                    <p>
                        <ul>
                            <li>
                                <Link to="/dmx/controllers/proton">
                                    Proton Main Page
                                </Link>
                            </li>
                            <li>
                                <Link to="/software/dmx/dcsm">
                                    DCSM Gateway
                                </Link> (Turn your Proton into a sACN/ArtNet node.)
                            </li>
                            <li>
                                <Link to="/software/dmx/dcsm">
                                    DMX Controller Serial Management (DCSM) Protocol
                                </Link>
                            </li>
                        </ul>
                    </p>

                    <h2>
                        Standard Operation
                    </h2>

                    <p>
                        To use the Proton, simply connect it to a computer running DCSM-compatible software
                        and configure the output in the program.
                    </p>

                    <h2>
                        Working with DCSM-Incompatible Software
                    </h2>

                    <p>
                        While software may not support DCSM, almost all support either sACN or ArtNet. DCSM
                        Gateway is a program that relays sACN/ArtNet data to connected DCSM devices. It turns
                        any software that supports sACN/ArtNet into software that also supports DCSM. You can
                        download it or learn more <Link to="/software/dmx/dcsm-gateway">here</Link>.
                    </p>

                    <h2>
                        Serial Commands
                    </h2>

                    <p>
                        Even on devices without DCSM-compatible software or a version of DCSM Gateway available,
                        as long as it has serial communication capacity, you can use serial commands for basic
                        control of a DCSM device. These commands can be sent to a DCSM device via any widely
                        available serial monitor with a baud rate of 115200. Here are some examples of commands:
                    </p>

                    <Code>
                        set 10 @ 200<br/>
                        set 1 thru 4 @ 50%<br/>
                        set 200 thru 300 even @ 10%<br/>
                        get 200 thru 250
                    </Code>

                    <h2>
                        Warranty, Replacement, and Repair
                    </h2>

                    <p>
                        We offer a one-year limited warranty to fix or replace your device if there is an issue
                        arising from manufacture error. As long as the device has been used as intended (that is,
                        it has been taken care of, and there is no extreme user-inflicted damage, either intentional
                        or negligent), we will make sure you continue to have a working device.
                    </p>
                    <p>
                        Even outside of the warranty, we support your right to repair, and we will offer firmware
                        and other support to fix your device. If there are any problems, contact us at [contact @ goddard . systems].
                    </p>
                </Article>
            </MainContent>
        </>
    );
}