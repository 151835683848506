import styled from "styled-components";

export default styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    background-color: black;
    z-index: 1000;
    
    .logo, .logo-name {
        height: 50px;
        margin: 5px -10px 5px 10px;
        cursor: pointer;
    }

    @media screen and (min-width: 500px) {
        height: 80px;

        .logo, .logo-name {
            height: 50px;
            margin: 15px -10px 15px 15px;
            cursor: pointer;
        }
    }
`;