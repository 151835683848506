import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Proton from "./pages/DMXControllers/Proton";
import DCSM from "./pages/DCSM";
import DCSMGateway from "./pages/DCSM/Gateway";
import ProtonDocs from "./pages/Docs/Proton";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes path="/">
                <Route index element={<Home />}/>
                <Route path="about" element={<About />} />
                <Route path="dmx">
                    <Route path="controllers">
                        <Route path="proton" element={<Proton />} />
                    </Route>
                </Route>
                <Route path="software">
                    <Route path="dmx">
                        <Route path="dcsm" element={<DCSM />} />
                        <Route path="dcsm-gateway" element={<DCSMGateway />} />
                    </Route>
                </Route>
                <Route path="docs">
                    <Route path="proton" element={<ProtonDocs />} />
                </Route>
                <Route path='*' element={<>404</>} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
