import styled from "styled-components";
import Container from "./Container";
import {useNavigate} from "react-router-dom";

export default function SectionItem(props) {
    const navigate = useNavigate();

    return (
        <Container onClick={() => props.to && navigate(props.to)}>
            {props.children}
        </Container>
    )
}