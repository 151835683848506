import styled from "styled-components";

export default styled.h2`
    font-size: 15px;
    color: #444;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin: 8px 0 0 15px;
    padding: 0;
    cursor: pointer
`;


