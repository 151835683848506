import Container from "./Container";
import CloseButton from "./CloseButton";
import Background from "./Background";

export default function MenuPage(props) {
    return (
        <Container className={props.active && "active"}>
            <CloseButton onClick={props.onClose} />
            {props.children}
        </Container>
    );
}