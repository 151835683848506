import {createStorefrontApiClient} from '@shopify/storefront-api-client';

import Navbar from "components/Navbar";
import ProductPage from "../../../components/ProductPage";
import MainContent from "../../../components/MainContent";

import ImageProton from "assets/proton1600.png";
import {Link} from "react-router-dom";
import useScript from "../../../hooks/useScript";
import ShopifyButton from "../../../components/ShopifyButton";
import {useState} from "react";

const shopifyButtonSettings = {
    domain: "899d02-f7.myshopify.com",
    storefrontAccessToken: "5d6678082c9499e4e316ddf78159fefe",
    productId: "9151952453923",
    buttonText: "View product"
};

const client = createStorefrontApiClient({
    storeDomain: '899d02-f7.myshopify.com',
    apiVersion: '2024-04',
    publicAccessToken: '1bd8919ce43481b314fca4795194ea0f',
});

export default function Proton() {
    document.title = "Proton - DMX Controllers - Goddard Systems";

    const [inventory, setInventory] = useState(null);

    client.request(
        `
            query ProductQuery($handle: String) {
                product(handle: $handle) {
                    handle,
                    totalInventory,
                    title
                }
            }
        `,
        {
            variables: {
                handle: 'proton-usb-dmx-controller'
            }
        }
    )
        .then((data) => data.data.product.totalInventory)
        .then(setInventory);

    return (
        <>
            <Navbar />

            <MainContent>
                <ProductPage>
                    <img src={ImageProton}/>

                    <h1>Proton</h1>
                    <h1sub>
                        USB DMX Controller
                    </h1sub>

                    <ShopifyButton config={shopifyButtonSettings}/>
                    <p
                        style={{
                            marginTop: "-30px"
                        }}
                    >
                        {
                            inventory === 0 ?
                                "Out of stock." :
                                <><b style={{marginRight: "3px"}}>{inventory}</b> available.</>
                        }
                    </p>

                    <p>
                        <i>
                            Two-week lead time on additional items.
                        </i>
                    </p>

                    <h2>
                        Highlights
                    </h2>
                    <p>
                        As opposed to other controllers, the Proton features:
                        <ul>
                            <li>Versatile DCSM protocol</li>
                            <li>High-quality, Neutrik 5-pin XLR connector</li>
                            <li>Buffered</li>
                            <li>Galvanic isolation</li>
                            <li>Low-profile body</li>
                        </ul>
                    </p>
                    <h2>
                        Overview
                    </h2>
                    <p>
                        The Proton is a USB DMX controller. As opposed to other similar controllers,
                        it is MCU-based, allowing buffered output and ensuring that even if a computer
                        or other device stops sending data to the controller, it will still produce a
                        reliable DMX output.
                    </p>
                    <p>
                        It also features galvanic isolation, which, in addition to producing a cleaner
                        DMX signal, protects the host device from any stray high voltage on the DMX line
                        resulting from a fault.
                    </p>
                    <p>
                        The Proton utilizes an open-source protocol, the <i>DMX Controller Serial
                        Management</i> (DCSM) protocol, for communicating with buffered DMX devices.
                        In addition to other benefits, this allows any commonly available serial
                        monitor application to send human-readable commands to the controller, even
                        if the platform lacks any specific software for DCSM. This includes Android
                        phones (and, in the future, hopefully, iPhones, which currently lack serial
                        communication). Among the many applications, this makes the Proton ideal
                        for quick testing and debugging of DMX devices without needing a full lighting
                        console or a still cumbersome laptop/dongle combination. See more about the
                        DCSM protocol <Link to={"/software/dmx/dcsm"}>here</Link>.
                    </p>
                    <h2>
                        Links
                    </h2>
                    <p>
                        <ul>
                            <li><Link to="/docs/proton">Instructions, documentation, etc.</Link></li>
                        </ul>
                    </p>
                </ProductPage>
            </MainContent>
        </>
    );
}