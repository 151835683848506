import Navbar from "../../../components/Navbar";
import MainContent from "../../../components/MainContent";
import Article from "../../../components/Article";

export default function DCSMGateway() {
    return (
        <>
            <Navbar />

            <MainContent>
                <Article>
                    <h1>DCSM Gateway</h1>

                    <h2>
                        Downloads
                    </h2>

                    <p>
                        <ul>
                            <li>
                                <a href="https://cdn.goddard.systems/software/dcsm-gateway/latest?platform=win64"
                                   target="_blank"
                                   rel="noreferrer noopener">
                                    Windows 64-bit - Latest
                                </a>
                            </li>
                        </ul>
                    </p>

                    <h2>
                        About
                    </h2>

                    <p>
                        Have a DCSM device but your lighting software of choice doesn't support it? Almost every
                        lighting program supports sACN or ArtNet, and with DCSM Gateway, sACN and/or ArtNet data
                        can be sent to all your DCSM devices.
                    </p>

                    <p>
                        The lighting software and DCSM Gateway don't need to be on the same device. As long as
                        there is a network connection between two computers, and the sACN/ArtNet data is being
                        transmitted, DCSM Gateway will relay the data to the DCSM device. This has the benefit
                        of allowing a DCSM device to act as a sACN/ArtNet node.
                    </p>
                </Article>
            </MainContent>
        </>
    );
}