export const ShopifyJS = (componentId, data) => `
(function () {
  var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
        domain: '${data.domain}',
        storefrontAccessToken: '${data.storefrontAccessToken}',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('product', {
        id: '${data.productId}',
        node: document.getElementById('${componentId}'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: {
          "product": {
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px"
                },
                "text-align": "left"
              },
              "button": {
                "font-family": "Roboto, sans-serif",
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                ":hover": {
                  "background-color": "#000000"
                },
                "background-color": "#000000",
                ":focus": {
                  "background-color": "#000000"
                },
                "border-radius": "0px"
              },
              "quantityInput": {
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px"
              }
            },
            "contents": {
              "img": false,
              "button": false,
              "buttonWithQuantity": true,
              "title": false,
              "price": false
            },
            "text": {
              "button": "Add to cart"
            },
            "googleFonts": [
              "Roboto"
            ]
          },
          "productSet": {
            "styles": {
              "products": {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px"
                }
              }
            }
          },
          "modalProduct": {
            "contents": {
              "img": false,
              "imgWithCarousel": true,
              "button": false,
              "buttonWithQuantity": true
            },
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px"
                }
              },
              "button": {
                "font-family": "Roboto, sans-serif",
                "font-size": "18px",
                "padding-top": "17px",
                "padding-bottom": "17px",
                ":hover": {
                  "background-color": "#000000"
                },
                "background-color": "#000000",
                ":focus": {
                  "background-color": "#000000"
                },
                "border-radius": "0px"
              },
              "quantityInput": {
                "font-size": "18px",
                "padding-top": "17px",
                "padding-bottom": "17px"
              }
            },
            "googleFonts": [
              "Roboto"
            ],
            "text": {
              "button": "Add to cart"
            }
          },
          "option": {},
          "cart": {
            "styles": {
              "button": {
                "font-family": "Roboto, sans-serif",
                "font-size": "18px",
                "padding-top": "17px",
                "padding-bottom": "17px",
                ":hover": {
                  "background-color": "#000000"
                },
                "background-color": "#000000",
                ":focus": {
                  "background-color": "#000000"
                },
                "border-radius": "0px"
              }
            },
            "text": {
              "total": "Subtotal",
              "empty": "There's nothing here! Add something to your cart to continue.",
              "button": "Checkout"
            },
            "popup": false,
            "googleFonts": [
              "Roboto"
            ]
          },
          "toggle": {
            "styles": {
              "toggle": {
                "font-family": "Roboto, sans-serif",
                "background-color": "#000000",
                ":hover": {
                  "background-color": "#000000"
                },
                ":focus": {
                  "background-color": "#000000"
                }
              },
              "count": {
                "font-size": "18px"
              }
            },
            "googleFonts": [
              "Roboto"
            ]
          }
        },
      });
    });
  }
})();
`;