import styled from "styled-components";

export default styled.div`
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    padding-bottom: 200px;

    @media screen and (min-width: 500px) {
        top: 80px;
    }
`;