import styled from "styled-components";

export default styled.div`
    width: calc(100% - 40px);
    margin: 0 auto;
    font-family: "Inter", sans-serif;

    p {
        font-size: 10pt;
        line-height: 1.5;
        text-align: justify;
    }

    h1 {
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    
    li {
        text-align: left;
        margin-bottom: 5px;
    }

    h2 {
        color: #333;
        font-weight: 500;
        margin-top: 40px;

        ::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background: #999;
            margin-top: 5px;
        }
    }

    @media screen and (min-width: 800px) {
        width: 700px;
        padding-top: 50px;

        h1 {
            margin-top: 30px;
            text-align: center;
        }

        p {
            font-size: 12pt;
        }
    }
    
`;