import Container from "./Container";
import Title from "./Title";

export default function Section(props) {
    return (
        <Container>
            <Title>{props.title}</Title>
            {props.children}
        </Container>
    );
}