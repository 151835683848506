import styled from "styled-components";

export default styled.div`
    margin: 40px 0;
    
    & > iframe {
        display: none !important;
    }
    
    & iframe:first-child {
        display: initial !important;
    }
`;