import Navbar from "components/Navbar";
import MainContent from "../../components/MainContent";
import Container from "./Container";
import MaxGoddardImage from "assets/MaxGoddard.jpg";

export default function About() {
    document.title = "About - Goddard Systems";

    return (
        <>
            <Navbar />

            <MainContent>
                <Container>
                    <img src={MaxGoddardImage} />
                    <div>
                        <p>
                            Hello! I'm Max Goddard and the founder of Goddard Systems. I'm the wizard behind all the
                            magic available on this site.
                        </p>
                        <p>
                            My journey began while volunteering as a theatre technician for my old high school. It was
                            an indescribably rewarding experience. I was able to deep dive into lighting, sound, and A/V
                            systems and learn how they worked. I took a great interest in lighting, though. I was
                            fascinated by lighting control, how it worked, and the logistics behind it all.
                        </p>
                        <p>
                            Occasionally, some lighting fixtures would break or needed to be tested for whatever reason.
                            A simple dimming light was easy enough, but DMX-enabled fixtures were a bit trickier, since
                            the only device that could output DMX to test the lights was the light board. Even with the
                            mobile RFR capability offered by the ETC board we had, testing an LED light while it was
                            sitting on the repair bench was infuriating (especially only being able to patch channels at
                            the board itself).
                        </p>
                        <p>
                            Looking for an easier solution, I tried to search up USB DMX controllers, so I could simply
                            test the lights with a laptop sitting on the bench. Unfortunately, all the more professional
                            controllers (the ones with proper five-pin XLR connectors) were extremely expensive and out
                            of
                            my price range as a student and volunteer.
                        </p>
                        <p>
                            The DMX protocol itself also interested me. I had done a lot of research into it and how it
                            works. Armed with this knowledge, I took on an attitude of, "How hard could it be to just
                            build my own controller?"
                        </p>
                        <p>
                            Turns out that it's kind of difficult. After trying to manually generate the DMX signal,
                            using
                            microcontrollers unsuited for the task, and many failures, I finally achieved my first clean
                            DMX output.
                        </p>
                        <p>
                            It was both easier and harder than I had expected. Though, to save other people the effort,
                            I
                            wanted to make my own controller, and I wanted to make it better than the current offerings.
                        </p>
                        <p>
                            Unlike the competition, our first commercial USB DMX controller features isolation,
                            buffering,
                            and a serial interface which allows sending human-readable commands directly to the device
                            (which also means that any device that can be installed with a serial terminal can also use
                            the device; that also means you can connect it directly to a phone and use that instead of a
                            laptop or other computer!).
                        </p>
                    </div>
                </Container>
            </MainContent>
        </>
    );
}