import Container from "./Container";
import Logo from "../Logo";
import {useNavigate} from "react-router-dom";
import Menu from "./Menu";
import MenuItem from "./Menu/MenuItem";
import MenuPage from "./Menu/MenuPage";
import {useEffect, useState} from "react";
import Section from "./Menu/MenuPage/Section";
import SectionItem from "./Menu/MenuPage/Section/SectionItem";
import Background from "./Menu/MenuPage/Background";

export default function Navbar() {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const [page, setPage] = useState(null);

    useEffect(() => {
        setPage(null);
    }, [expanded]);

    return (
        <>
            <Container>
                <Background style={{ display: page ? "block" : "none" }} onClick={() => setPage(null)} />
                <Logo
                    color="#FFFFFF"
                    nameLogo={true}
                    onClick={() => navigate("/")}
                />
                <Menu expanded={expanded} onToggle={() => setExpanded(!expanded)}>
                    <MenuItem onClick={() => window.location = "https://store.goddard.systems"}>Marketplace</MenuItem>
                    <MenuItem onClick={() => setPage(page === "products" ? null : "products")}>Solutions</MenuItem>
                    <MenuItem onClick={() => navigate("/about")}>About</MenuItem>
                </Menu>
                <MenuPage
                    active={page === "products"}
                    onClose={() => setPage(null)}
                >
                    <Section title="DMX Controllers">
                        <SectionItem to="/dmx/controllers/proton">Proton</SectionItem>
                        {/*<SectionItem to="/dmx/controllers/multitool">Multitool</SectionItem>*/}
                    </Section>
                    <Section title="DMX/Lighting Software">
                        {/*<SectionItem to="/dmx/software/bonfire">Bonfire - DMX Lighting</SectionItem>*/}
                        <SectionItem to="/software/dmx/dcsm-gateway">DCSM Gateway</SectionItem>
                        <SectionItem to="/software/dmx/dcsm">DMX Serial Management (DCSM)</SectionItem>
                    </Section>
                    {/*
                    <Section title="MoNET">
                        <SectionItem to="/monet">Overview</SectionItem>
                        <SectionItem to="/monet/units">Units</SectionItem>
                        <SectionItem to="/monet/installation">Installation</SectionItem>
                        <SectionItem to="/monet/software">Software</SectionItem>
                    </Section>
                    */}
                </MenuPage>
            </Container>
        </>
    );
}