import styled from "styled-components";
import {useNavigate} from "react-router-dom";

import Navbar from "components/Navbar";
import MainContent from "../../components/MainContent";
import Container from "./Container";

import Carousel from "../../components/Carousel";
import Slide from "../../components/Carousel/Slide";
import Explore from "./Explore";
import CarouselWrapper from "./CarouselWrapper";

import ImageMonetInterface from "assets/monet-interface.png";
import ImageProton from "assets/proton1600.png";
import ImageGateway from "assets/gsgateway.png";

export default function Home() {
    const navigate = useNavigate();

    document.title = "Home - Goddard Systems";

    return (
        <>
            <Navbar />

            <MainContent>
                <Container>
                    <h1>
                        GODDARD SYSTEMS
                    </h1>
                    <div className="divider"></div>
                    <h2>
                        Control systems, theatre lighting technology, and more.
                    </h2>

                    <Explore />
                </Container>
                <CarouselWrapper>
                    <Carousel>
                        <Slide
                            caption={"Proton, a USB DMX controller utilizing the DCSM protocol."}
                        >
                            <img
                                src={ImageProton}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                    cursor: "pointer"
                                }}
                                alt={"Proton"}
                                onClick={() => navigate("/dmx/controllers/proton")}
                            />
                        </Slide>
                        <Slide
                            caption={"DCSM Gateway, an application to output data from sACN/ArtNet to a DCSM device."}
                        >
                            <img
                                src={ImageGateway}
                                style={{
                                    height: "100%",
                                    width: "calc(100% * 9/16)",
                                    objectFit: "contain",
                                    cursor: "pointer"
                                }}
                                alt={"Proton"}
                                onClick={() => navigate("/software/dmx/dcsm-gateway")}
                            />
                        </Slide>
                        <Slide
                            caption={"The main interface of MoNET, a comprehensive central control system. Currently in development."}
                        >
                            <img
                                src={ImageMonetInterface}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain"
                                }}
                                alt={"MoNET"}
                            />
                        </Slide>
                    </Carousel>
                </CarouselWrapper>
            </MainContent>
        </>
    );
}