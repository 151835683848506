import {SwiperSlide} from "swiper/react";
import Caption from "./Caption";
import Container from "./Container";

export default function Slide(props) {
    return (
        <Container>
            {props.children}
        </Container>
    );
}