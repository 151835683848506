import Container from "./Container";
import {ShopifyJS} from "./shopifyButton";
import useScript from "../../hooks/useScript";
import {useState} from "react";
import randomstring from "randomstring";

export default function ShopifyButton(props) {
    const [componentId, setComponentId] = useState(
        "product-component-" +
        randomstring.generate({
            length: 12,
            charset: "numeric"
        })
    );

    useScript(ShopifyJS(componentId, props.config));

    return (
        <Container id={componentId} ></Container>
    );
}