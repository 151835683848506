import MenuIcon from '@mui/icons-material/Menu';
import Container from "./Container";

export default function Hamburger(props) {
    return (
        <Container>
            <div>
                <MenuIcon
                    onClick={props.onClick}
                />
            </div>
        </Container>
    );
}