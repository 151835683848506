import styled from "styled-components";

export default styled.div`
    position: relative;
    
    .swiper-wrapper {
        align-items: center;
    }
    
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .swiper-button-prev, .swiper-button-next {
        color: #999;
    }
    
    .swiper-pagination-bullet-active-main {
        background: white;
    }
    
    .swiper-pagination-bullet {
        background: #999;
    }
    
    .swiper-pagination {
        bottom: 20px;
    }
`;