import styled from "styled-components";

export default styled.div`
    position: fixed;
    display: table;
    top: 0;
    right: 0;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: white;
    z-index: 1100;
`;