import Container from "./Container";
import Hamburger from "./Hamburger";
import {useState} from "react";
import MenuItemList from "./MenuItemList";
import Background from "./MenuPage/Background";

export default function Menu(props) {
    return (
        <Container>
            <MenuItemList className={props.expanded && "expanded"}>
                {props.children}
            </MenuItemList>
            <Hamburger onClick={props.onToggle} />
        </Container>
    )
}