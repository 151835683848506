import styled from "styled-components";

export default styled.div`
    text-align: center;
    padding: 50px 20px;
    margin-bottom: 30px;
    font-family: "Inter", sans-serif;
    height: 100vh;
    
    h1 {
        font-size: 40px;
        font-weight: 900;
        margin: 100px 0 15px 0;
    }
    
    .divider {
        display: inline-block;
        width: 250px;
        height: 8px;
        background: black;
    }
    
    h2 {
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 0;
    }
`;
