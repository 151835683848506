import styled from "styled-components";

export default styled.div`
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFF;
    transform: translateX(100%);
    transition: transform 100ms linear;
    z-index: 1100;
    user-select: none;
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        bottom: 10px;
        width: 2px;
        background: #222;
    }
    
    &.active {
        transform: translate(0%);
    }
    
    @media screen and (min-width: 500px) {
        transition: none;
        top: 80px;
        bottom: initial;
        height: 250px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        
        filter: drop-shadow(0 1px 4px #000);
    }
`;