import styled from "styled-components";

export default styled.div`
    display: none;
    
    @media screen and (min-width: 500px) {
        display: block;
        position: fixed;
        top: 80px;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 700;
    }
`;