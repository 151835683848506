import styled from "styled-components";

export default styled.div`
    text-align: center;
    padding: 0 20px;
    
    img {
        width: 170px;
        height: 170px;
        margin-top: 40px;
        border-radius: 100%;
    }

    p {
        text-align: justify;
    }

    @media screen and (min-width: 600px) {
        display: flex;
        width: 550px;
        margin: 50px auto;
        
        img {
            margin-right: 50px;
        }
    }
`;