import styled from "styled-components";

export default styled.div`
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    
    h3 {
        margin-bottom: 10px;
    }
    
    svg {
        font-size: 30px;
    }
`;