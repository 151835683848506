import styled from "styled-components";

export default styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
    & > svg {
        font-size: 30px;
        color: black;
    }
`;