import styled from "styled-components";

export default styled.div`
    display: block;
    margin: 20px 0 0 30px;
    cursor: pointer;
    user-select: none;
    
    @media screen and (min-width: 500px) {
        display: table-cell;
        vertical-align: bottom;
        margin: initial;
        padding-right: 30px;
    }
`;