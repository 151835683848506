import {Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import ImageMonetInterface from "../../assets/monet-interface.png";
import Container from "./Container";
import Caption from "./Slide/Caption";
import {useEffect, useState} from "react";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Carousel(props) {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        console.log(slide);
    }, [slide]);

    const children = Array.isArray(props.children) ? props.children : [ props.children ];

    return (
        <Container
            {...props}
        >
            <Swiper
                slidesPerView={1}
                loop={true}
                pagination={{
                    dynamicBullets: true
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                {...props}
                onSlideNextTransitionEnd={() => setSlide(slide => ++slide === props.children.length ? 0 : slide)}
                onSlidePrevTransitionEnd={() => setSlide(slide => --slide < 0 ? props.children.length - 1 : slide)}
            >
                {children.map((element, index) => (
                    <SwiperSlide key={index} {...props}>
                        {element}
                    </SwiperSlide>
                ))}
            </Swiper>

            {children.map((element, index) => element.props.caption && (
                <Caption key={index} style={{ display: slide === index ? "initial" : "none" }}>
                    {element.props.caption}
                </Caption>
            ))}
        </Container>
    );
}