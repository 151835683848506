import styled from "styled-components";

export default styled.div`
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 10px;
    background: #333;
    text-align: center;
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 12px;
`;