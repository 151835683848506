import styled from "styled-components";

export default styled.div`
    position: fixed;
    display: table;
    top: 0;
    right: 10px;
    height: 60px;
    width: 60px;
    vertical-align: middle;
    text-align: center;
    
    & > div {
        display: table-cell;
        vertical-align: middle;
    }
    
    & > div > * {
        font-size: 30px;
        margin-top: 2px;
        cursor: pointer;
    }

    @media screen and (min-width: 500px) {
        display: none;
    }
`;